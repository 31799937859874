import {
  CrazyEventCodeType,
  GlobalServerCheckTime,
} from '@src/responsive/globalProcess/context';

import Cookies from 'js-cookie';
import { MembershipCode } from '@src/constants';
import { TypeCheck } from '@toptoon-developers/global.toptoonplus.common.lib';
import { UserSession } from '..';
import _ from 'lodash';
import { _days } from '@toptoon-developers/global.toptoonplus.common.lib';
import advanced from 'dayjs/plugin/advancedFormat';
import crypto from 'crypto';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import isoWeek from 'dayjs/plugin/isoWeek';
import { parse } from 'cookie';
import timezone from 'dayjs/plugin/timezone';
import toObject from 'dayjs/plugin/toObject';
import utc from 'dayjs/plugin/utc';
import { v4 as uuid } from 'uuid';

dayjs.extend(utc); // day.utc 추가
dayjs.extend(duration); // day.duration 추가
dayjs.extend(timezone); // day.tz() 타임존 추가
dayjs.extend(advanced); // day.format() 의 포맷 형식을 확장
dayjs.extend(toObject); // day.toObject 날짜를 객체형식으로 반환
dayjs.extend(isoWeek); // day.isoWeek

const enum TimeZone {
  KOREA = 'Asia/Seoul',
}

/**
 * timezone의 현재 요일을 number로 반환
 * @timezone America/New_York
 * @returns
 */
export const getCurrentWeekly = (timezone: string): number => {
  let currentDay = _days.nowTimezone(timezone).day();
  if (currentDay === 0) currentDay = 6;
  else currentDay -= 1;

  return currentDay;
};

export const getLocalWeekly = (): number => {
  let currentDay = dayjs().day();
  if (currentDay === 0) currentDay = 6;
  else currentDay -= 1;

  return currentDay;
};

export const numberToBoolean = (data: any, key: string) => {
  if (!TypeCheck.itemsByPath(data, key)) return false;

  return data[key] === 1;
};

export const addComma = (num: number) => {
  try {
    const regexp = /\B(?=(\d{3})+(?!\d))/g;
    return num.toString().replace(regexp, ',');
  } catch (error) {
    return num;
  }
};

export const getOpenDate = (data: any, key: string) => {
  if (!TypeCheck.itemsByPath(data, key)) return '';

  const date = dayjs(data[key].date).tz('Asia/Tokyo');
  return date.format('MMMM DD,YYYY');
};

/**
 * Create a version 4 (random) UUID
 */
export const uuidv4 = () => {
  return uuid();
};

/**
 * getServerSideProps의 context에서 cookie를 받아 반환
 * @param context
 * @returns
 */
export const parseSSRCookies = (context: any) => {
  // For API Routes we don't need to parse the cookies.
  if (context.req.cookies) return context.req.cookies;

  // For pages we do need to parse the cookies.
  const cookie = context.req.headers?.cookie;
  return parse(cookie || '');
};

export const getSSRCookie = (context: any, key: string) => {
  const cookies = parseSSRCookies(context);
  if (cookies === '') return null;
  return cookies[key];
};

/**
 * refer 주소가 우리 주소인지 반환
 * @param context
 * @returns
 */
export const isRefererCheck = (context: any) => {
  const { referer } = context.req.headers;
  if (referer) {
    return (
      referer.includes(`${process.env.REACT_APP_PUBLIC_URL}`) ||
      referer.includes('localhost')
    );
  } else {
    return false;
  }
};

/**
 *  해당 문자열에 이모티콘 문자열이나 오미지가 있는지 판단
 * @param str
 */
export const isTextValidate = (str: string) => {
  try {
    const regexp = `/[\u{1f300}-\u{1f5ff}\u{1f900}-\u{1f9ff}\u{1f600}-\u{1f64f}\u{1f680}-\u{1f6ff}\u{2600}-\u{26ff}\u{2700}-\u{27bf}\u{1f1e6}-\u{1f1ff}\u{1f191}-\u{1f251}\u{1f004}\u{1f0cf}\u{1f170}-\u{1f171}\u{1f17e}-\u{1f17f}\u{1f18e}\u{3030}\u{2b50}\u{2b55}\u{2934}-\u{2935}\u{2b05}-\u{2b07}\u{2b1b}-\u{2b1c}\u{3297}\u{3299}\u{303d}\u{00a9}\u{00ae}\u{2122}\u{23f3}\u{24c2}\u{23e9}-\u{23ef}\u{25b6}\u{23f8}-\u{23fa}]/gu`;
    const result = str.replace(regexp, '');
    return str !== result;
  } catch (error) {
    return true;
  }
};

/**
 * 남은 기간 계산 함수 (day / hour / min 순으로 표기)
 * @param t : 목표 날짜 (UTC)
 */
export const remainingDate = (t: string) => {
  if (t === null || t === '') return '';

  const now = dayjs().utc();
  const date = dayjs(t).utc();

  const day = date.diff(now, 'days');
  if (day >= 1) {
    return `${day} D`;
  }

  const hour = dayjs.duration(date.diff(now)).hours();
  if (hour >= 1) {
    return `${hour} H`;
  }

  const min = dayjs.duration(date.diff(now)).minutes();
  if (min >= 1) {
    return `${min} M`;
  }
  return '';
};

export const splitNewValue = (value: any, option: string, checkValue?: any) => {
  let newValue = value;
  if (option.indexOf('.') !== -1) {
    const options = option.split('.');
    for (let i = 0; options.length > i; i++) {
      if (!newValue) {
        break;
      }
      newValue = newValue[options[i]];
    }
  } else {
    newValue = value[option];
  }
  return newValue || checkValue;
};

/**
 * 결제 금액 소수점 미표기
 * @param locale ex) ko-KR
 * @param currencyCode
 * @param price
 * @returns
 */
export const currencyAndPriceToCurrencySymbol = (
  locale: string,
  currencyCode: string,
  price: number,
  isOneDollar?: boolean,
) => {
  // 가격 소수점 반올림 처리됨 참조
  if (price % 1 === 0) {
    return new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: currencyCode,
      currencyDisplay: 'symbol',
      minimumFractionDigits: isOneDollar ? 1 : 0,
      maximumFractionDigits: isOneDollar ? 1 : 0,
    })
      .format(price)
      .replace('US$', '$');
  }

  // 가격 소수점 반올림 처리됨 참조
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currencyCode,
    currencyDisplay: 'symbol',
    minimumFractionDigits: isOneDollar ? 2 : 0,
    maximumFractionDigits: isOneDollar ? 2 : 0,
  })
    .format(price)
    .replace('US$', '$');
};

/**
 * 결제 금액 소수점 표기
 * @param locale ex) ko-KR
 * @param currencyCode
 * @param price
 * @param decimalPoint // 소수점 자리설정옵션
 * @returns
 */
export const currencyAndPriceToCurrencySymbolWithFractionDigits = (
  locale: string,
  currencyCode: string,
  price: number,
  decimalPoint: number = 2,
) => {
  // 가격 소수점
  if (price % 1 === 0) {
    return new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: currencyCode,
      currencyDisplay: 'symbol',
      minimumFractionDigits: decimalPoint,
      maximumFractionDigits: decimalPoint,
    })
      .format(price)
      .replace('US$', '$');
  }

  // 가격 소수점
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currencyCode,
    currencyDisplay: 'symbol',
    minimumFractionDigits: decimalPoint,
    maximumFractionDigits: decimalPoint,
  })
    .format(price)
    .replace('US$', '$');
};

/**
 * 시작 시간, 끝나는 시간을 받아 limit 시간 체크
 *  * timezome america/newYork
 *  * "미친결제"에서 사용중.
 * @param start
 * @param end
 * @returns
 */
export const LimitCheck = (start: string, end: string) => {
  const TIMEZONE = 'America/New_York';

  const now = dayjs().tz(TIMEZONE);
  const currentStart = dayjs(start).tz(TIMEZONE);
  const currentEnd = dayjs(end).tz(TIMEZONE);
  const remaningStartSec = dayjs.duration(currentStart.diff(now)).asSeconds();
  const remaningEndSec = dayjs.duration(currentEnd.diff(now)).asSeconds();

  if (remaningStartSec < 0 && remaningEndSec > 0) {
    return true;
  }

  return false;
};

/**
 * 요청시간 확인
 * @param utc 시간 문자열 2021-11-10 12:00
 * @returns boolean
 */
export const isDueDateFromKst = (utc: string) => {
  if (utc === null || utc === '') return '';

  const now = dayjs.utc();
  const utcDate = dayjs(utc).utc(); // KST to UTC
  return now.unix() - utcDate.unix() > 0; // 현재 - 입력값 (음수: 미래, 양수: 과거)
};

/**
 * 시작일 ~ 종료일 확인 함수
 * @param startKst 한국 시간 문자열 2022-05-29T13:00:00+09:00
 * @param endKst 한국 시간 문자열 2022-05-29T13:00:00+09:00
 * @returns
 */
export const isDuration = (startUtc: string, endUtc: string) => {
  const result =
    isDueDateFromKst(startUtc) === true && isDueDateFromKst(endUtc) === false;
  return result;
};

/**************************************
 * 서버체크 관련 함수
 * 최종 수정 : 22.11.10
 * 서버점검 시작, 종료 시간 globalprocess에서 받아옴.
 * 서버점검시 화면은 서버에서 알아서 endtime 설정.
 ****************************************/
export const convertServerCheckTime = (
  start: Date | string,
  end: Date | string,
) => {
  const startTimezone = _days.timezone(start, 'EST');
  const endTimezone = _days.timezone(end, 'EST');

  const startTime = {
    time: startTimezone.format('hh:mm a'),
    date: startTimezone.format('dddd, MMMM DD'),
    year: startTimezone.format('YYYY'),
  };

  const endTime = {
    time: endTimezone.format('hh:mm a'),
  };

  return { startTime, endTime };
};

/**
 * 서버 점검시간 1시간 전
 */
export const isServerCheckNotiTime = (time: GlobalServerCheckTime): boolean => {
  if (!time) return false;

  const { startDate, endDate } = time;

  const now = _days.timezone('now', 'EST');

  // 서버 점검 시작시간 1시간 전
  const startTime = _days.timezone(startDate, 'EST').add(-1, 'h');
  // 서버 점검 종료시간
  const endTime = _days.timezone(endDate, 'EST');

  // 현재 시간과 비교.
  const start = now.diff(_days.timezone(startTime, 'EST'));
  const end = now.diff(_days.timezone(endTime, 'EST'));

  if (start < 0 || end > 0) return false;

  const dadte = '';
  return true;
};

export const coinCountingUnit = (coin: number, upper: boolean) => {
  const pluralize = require('pluralize');
  if (upper) return pluralize('COIN', coin);
  return pluralize('Coin', coin);
};

export const coinToCentUnit = (coin: number) => {
  return `${coin * 25}￠`;
};

/**
 * 멤버십 상품 개월수 단복수 치환
 * @periodValue 개월수
 * @firstUpper 첫글자 대문자로 변경할 것인지 여부 (기본값 false)
 * @returns
 */
export const membershipUnit = (
  periodValue: string | number,
  firstUpper: boolean = false,
) => {
  const pluralize = require('pluralize');
  if (firstUpper) return pluralize('Month', Number(periodValue));
  return pluralize('month', Number(periodValue));
};

/**
 * 첫글자를 대문자로
 * @param value
 * @returns
 */
export const toFirstUpperCase = (value: string): string => {
  if (value.length === 0) return '';

  const r = value[0].toUpperCase() + value.slice(1, value.length);

  return r;
};

/**
 * 코인소진창에서 사용되는 보너스 코인 남은기간 표기 함수
 * 로컬 시간 utc 변환
 * 서버 시간 utc
 * @param value
 * @returns
 */
export const getBonusCoinLimit = (date: string) => {
  if (!date || date.length === 0) return '';

  const now = dayjs.utc();
  const utcDate = dayjs(date);
  const diffDay = dayjs.duration(utcDate.diff(now));

  const days = Math.floor(diffDay.asDays());
  const hours = diffDay.hours();
  const min = diffDay.minutes();

  const add0 = (value: number): string => {
    if (value >= 10) return `${value}`;
    return `${value}`;
  };

  if (days > 0) {
    return `${add0(days)} Days ${add0(hours)} Hr ${add0(min)} Min`;
    // return diffDay.format('DD Days HH Hr 25 MM Min');
  } else if (hours > 0) {
    return `${add0(hours)} Hr ${add0(min)} Min`;
  } else if (min > 0) {
    return `${add0(min)} Min`;
  } else {
    return '1 Min';
  }
};

/**
 * ANCHOR: 2022.10.27 ~~
 * * 현재 [Logo && GnbMenu] 사용중
 * 2022.12.21 - MOON
 * * 이벤트 적용구간 [한국시간] -> [현지시간]으로 변경
 * halloween
 */
const enum EventTimerCode {
  STRAT_AT = '2022-12-15 15:00:00', // 이벤트 시작시간
  END_AT = '2022-12-28 15:00:00', // 이벤트 종료시간
  TIME_ZONE = 'Asia/Seoul',
}
export const isEvent = (): boolean => {
  // 한국시로 입력한 값을 utc로 변경해서 사용하도록 설계함
  const startAt = dayjs
    .tz(EventTimerCode.STRAT_AT, EventTimerCode.TIME_ZONE)
    .utc();
  const endAt = dayjs.tz(EventTimerCode.END_AT, EventTimerCode.TIME_ZONE).utc();

  const now = dayjs.utc();

  const remaningStartSec = dayjs.duration(startAt.diff(now)).asSeconds();
  const remaningEndSec = dayjs.duration(endAt.diff(now)).asSeconds();

  if (remaningStartSec < 0 && remaningEndSec > 0) {
    return true;
  }

  return false;
};

const PromoEventTimerCode = {
  STRAT_AT: '2023-11-21 17:00:00', // 이벤트 시작시간
  END_AT: '2023-11-20 17:00:00', // 이벤트 종료시간
  TIME_ZONE: 'Asia/Seoul',
};
/**
 * ANCHOR: 카테고리 메뉴(PROMO) 비노출 처리용
 * 특정 기간 설정하여 비노출 처리
 * 이벤트 적용구간 [한국시간] -> UTC로 변경
 * 라이브 시간: 11/21(화) 17:00 ~ 11/29(화) 17:00 KST
 */
export const isPromoHidden = (): boolean => {
  // 한국시로 입력한 값을 utc로 변경해서 사용하도록 설계함
  const startAt = dayjs
    .tz(PromoEventTimerCode.STRAT_AT, PromoEventTimerCode.TIME_ZONE)
    .utc();
  const endAt = dayjs
    .tz(PromoEventTimerCode.END_AT, PromoEventTimerCode.TIME_ZONE)
    .utc();

  const now = dayjs.utc();

  const remaningStartSec = dayjs.duration(startAt.diff(now)).asSeconds();
  const remaningEndSec = dayjs.duration(endAt.diff(now)).asSeconds();

  if (remaningStartSec < 0 && remaningEndSec > 0) {
    return true;
  }

  return false;
};

/**
 * ANCHOR: 맴버쉽 상태 체크 (전역사용)
 * 맴버쉽 현재 사용중인 것은 프리미엄 등급만 있음 (시즌패스는 해당값 주지않음)
 * @returns 멤버십 여부
 */
export const isMemberShip = (): boolean => {
  const { userSubscribe } = UserSession.getCoinInfo();
  const { token } = UserSession.getUserInfo();

  if (token === '' || !userSubscribe) return false;

  if (!userSubscribe) return false;

  if (userSubscribe.code === MembershipCode.PREMIUM) return true;
  else return false;
};

/**
 * ANCHOR: 암호화
 * @param text 암호화 할 텍스트
 */
export const encrypt = (text: string) => {
  const ENCRYPTION_KEY = process.env.CLIENT_REQUEST_SECRET_KEY?.repeat(2);
  const IV_LENGTH = 16;

  if (!ENCRYPTION_KEY) return false;

  const iv = crypto.randomBytes(IV_LENGTH);
  const cipher = crypto.createCipheriv(
    'aes-256-cbc',
    Buffer.from(ENCRYPTION_KEY),
    iv,
  );
  const encrypted = cipher.update(text);

  return `${iv.toString('hex')}:${Buffer.concat([
    encrypted,
    cipher.final(),
  ]).toString('hex')}`;
};

/**
 * ANCHOR: 정기 미치결제 레이어 용 쿠키설정
 * 매일 자정(23시59분59초) 자동 삭제
 */
export const setCrazyEventModal = () => {
  const date = new Date();
  const resetDate = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    23,
    59,
    59,
  );

  Cookies.set(CrazyEventCodeType.CRAZY_DURATION, 'true', {
    expires: resetDate,
  });
};

/**
 * ANCHOR: 갱신쿠키 설정 함수
 * 로컬시간 기준 24시간 만료 쿠키 설정
 * @param expiredHour
 */
export const REFRESH_COOKIE_AUTOREFILL_LAYER = '_r_c_e_d';
export const setRefreshCookie = (key: string) => {
  Cookies.set(key, 'true', {
    expires: 1,
  });
};

export const getRefreshCookie = (key: string): string | undefined => {
  const getCookie = Cookies.get(key);
  if (!getCookie) {
    return getCookie;
  }
  return getCookie;
};

/**
 * 할로윈 기간에 대한 처리 함수
 */
export const isHalloween = (): boolean => {
  // 한국시로 입력한 값을 utc로 변경해서 사용하도록 설계함
  const startAt = dayjs.tz('2023-10-12 16:55:00', 'Asia/Seoul').utc();
  const endAt = dayjs.tz('2023-10-14 17:00:10', 'Asia/Seoul').utc();

  const now = dayjs.utc();

  const remaningStartSec = dayjs.duration(startAt.diff(now)).asSeconds();
  const remaningEndSec = dayjs.duration(endAt.diff(now)).asSeconds();

  if (remaningStartSec < 0 && remaningEndSec > 0) {
    return true;
  }

  return false;
};

/****************************************************************************
 * black friday 레이어 관련
 * 2023/11/28
 *****************************************************************************/
const BLACKFRIDAY_LAYER_TIMELINE = [
  '2023-11-21 08:00:00', // 1번 시작시간(시작시간)
  '2023-11-22 15:00:00', // 2번 시작시간
  '2023-11-23 08:00:00', // 3번 시작시간
  '2023-11-26 08:00:00', // 4번 시작시간
  '2023-11-26 15:00:00', // 5번 시작시간
  '2023-11-28 08:00:00', // 종료 시간
];
// 테스트 환경용
const BLACKFRIDAY_LAYER_TIMELINE_TEST = [
  '2023-11-17 08:00:00', // 1번 시작시간(시작시간)
  '2023-11-17 08:10:00', // 2번 시작시간
  '2023-11-17 08:20:00', // 3번 시작시간
  '2023-11-17 08:30:00', // 4번 시작시간
  '2023-11-17 08:40:00', // 5번 시작시간
  '2023-11-17 08:50:00', // 종료 시간
];
/**
 * 블랙프라이데이 시간별 처리 (UTC) 1~5차 레이어가 시간별로 노출
 * * 1. 현재 시간이 black friday 시작시간, 종료시간 외의 시간이면 동작하지 않음.  return 0;
 * * 2. 현재시간이 layer[0]~layer[1] 사이의 시간이면 return 1
 * * ... 현재시간이 layer[i-1]~layer[i]사이의 시간이면 return i
 * * 변수 compare : layer[i-1] 해당
 * * 변수 current : layer[i] 해당
 */
export const getBlackFridayLayerNum = (): number => {
  const timeline =
    process.env.REACT_APP_ENVIRONMENT === 'production'
      ? BLACKFRIDAY_LAYER_TIMELINE
      : BLACKFRIDAY_LAYER_TIMELINE_TEST;

  const startAt = dayjs.tz(timeline[0], 'UTC');
  const endAt = dayjs.tz(timeline[5], 'UTC');
  const now = dayjs.utc();

  const remaningStartSec = dayjs.duration(startAt.diff(now)).asSeconds();
  const remaningEndSec = dayjs.duration(endAt.diff(now)).asSeconds();

  if (remaningStartSec > 0 || remaningEndSec < 0) {
    return 0;
  }

  let layerNum = 0;
  let compare = startAt;

  _.some(timeline, (i, index) => {
    // true: break, false:continue
    if (index === 0) {
      return false;
    }
    const current = dayjs.tz(i, 'UTC');
    const remaningPrevSec = dayjs.duration(compare.diff(now)).asSeconds();
    const remaningCurrentSec = dayjs.duration(current.diff(now)).asSeconds();
    if (remaningPrevSec < 0 && remaningCurrentSec > 0) {
      layerNum = index;
      return true;
    }
    compare = current;
  });

  return layerNum;
};

/**
 * 인자로 넘겨받은 값을 utc timestamp로 치환
 * @param target
 * @returns
 */
export const getUtcTimeStampByTarget = (target: string): number => {
  return dayjs(target).utc().valueOf();
};

/**
 * ANCHOR: 현재 날짜 및 시간을 timestamp로 변환
 * @returns
 */
export const getNowTimeStamp = (): number => {
  return dayjs().valueOf();
};
