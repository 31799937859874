import { getAuth, getRedirectResult } from '@firebase/auth';
import { useAppData, useTheLayouotCtrl } from '@src/hooks';
import { useEffect, useMemo, useState } from 'react';

import AppManager from '@src/lib/app/AppManager';
import { AuthController } from '@src/auth/AuthController';
import Cookies from 'js-cookie';
import { GlobalGtm } from '@toptoon-developers/global.toptoonplus.common.lib/dist/gtm/global';
import React from 'react';
import TestToolBox from '../components/testTools/TestToolBox';
import { TopcoLoadingController } from '../error/TopcoLoading';
import TopcoPageHistory from '@src/lib/utils/topcoPageHistory';
import UserCoinResponse from '@src/responsive/UserCoinResponse';
import UserDefault from '../../lib/user/UserDefault';
import { UserSession } from '@src/lib';
import _ from 'lodash';
import cn from 'clsx';
import dynamic from 'next/dynamic';
import { isEvent } from '@src/lib/utils/utils';
import { openAppUpdateModadl } from '../modal/appUpdate';
import useLogin from '@src/hooks/useLogin';
import { usePartner } from '@src/hooks/usePartner';
import { useRouter } from 'next/router';

const ApiResponse = dynamic<any>(() => import('@src/responsive/ApiResponse'), {
  ssr: false,
});

const GlobalEventResponse = dynamic<any>(
  () => import('@src/responsive/globalProcess/GlobalEventResponse'),
  { ssr: false },
);

const PersistResponse = dynamic<any>(
  () => import('@src/responsive/PersistResponse'),
  { ssr: false },
);

const Header = dynamic<any>(
  () => import('@src/view/theLayout/Header').then((mod: any) => mod.Header),
  { ssr: false },
);

const Footer = dynamic<any>(
  () => import('@src/view/theLayout/Footer').then((mod: any) => mod.Footer),
  { ssr: false },
);

const AcceptCookieComponent = dynamic<any>(
  () =>
    import('@src/view/components/acceptCookies').then(
      (mod: any) => mod.AcceptCookieComponent,
    ),
  { ssr: false },
);

const GlobalModalController = dynamic<any>(
  () => import('@src/middleware/GlobalModalController'),
  { ssr: false },
);

interface PropTypes {
  children: React.ReactNode;
}
const TheLayout = (props: PropTypes) => {
  const { children } = props;
  const router = useRouter();
  const [isMount, setIsMount] = useState(false);
  const { redirectUrl: rUrl } = usePartner(router);
  const { updateFcmToken } = useAppData();
  const { snsLoginApi } = useLogin();
  const { layoutHidden, isLoading } = useTheLayouotCtrl();
  const [appBlock, setAppBlock] = useState(false);
  const [isTestMod, setIsTestMod] = useState(false);

  const { headerHidden, footerHidden, bodyWrapperHidden } = layoutHidden;

  async function handleCallback(response: any) {
    AuthController.googleLogin(response.credential, () => {
      window.location.href =
        TopcoPageHistory.getInstance().getLastHistory().path;
    });
  }

  // GTM 객체 초기화 (환경변수 체크)
  useEffect(() => {
    GlobalGtm.getInstance().init(process.env.GOOGLE_TAG_MANAGER ?? '');
    setIsMount(true);
  }, []);

  useEffect(() => {
    if (Cookies?.get('topco_test') && UserDefault.isDevEnv()) {
      setIsTestMod(true);
    }

    // google login button initialize
    // => renderButton 전에 initialize 해줘야되는데 구글 버튼이 여러 개여서 sync 맞추기 위해 TheLayout으로 올림
    if (typeof window !== undefined) {
      const { google }: any = window;
      if (!google) return;

      google.accounts.id.initialize({
        client_id: `${process.env.REACT_APP_GOOGLE_ID}`,
        callback: handleCallback,
      });
    }
  }, []);

  useEffect(() => {
    //firebase 관련 로직
    const auth = getAuth();

    getRedirectResult(auth)
      .then(result => {
        if (result) {
          const { user } = result;
          const { uid } = user;
          snsLoginApi('apple', { uid });
        }
      })
      .catch(err => {
        console.error('error', err);
      });
  }, []);

  useEffect(() => {
    if (!appBlock && !isLoading) {
      TopcoLoadingController.hide();
    }

    const { token } = UserSession.getUserInfo();

    if (
      !(
        router.asPath.startsWith('/comic/') ||
        router.asPath.startsWith('/content/')
      ) &&
      Cookies.get('saveOrder')
    ) {
      Cookies.remove('saveOrder');
    }

    if (router.pathname === '/') {
      const { packageName, redirectUrl, fcmToken, version } = router.query;

      if (packageName !== undefined && version !== undefined) {
        const oldFcmToken = AppManager.getInstance().getFcmToken();
        AppManager.getInstance().setAppInfo({
          packageName: `${packageName}`,
          fcmToken: fcmToken ? `${fcmToken}` : '',
          version: `${version}`,
        });

        /**
         * 앱 업데이트 모달 노출
         * * 자체앱, 안드로이드, 98이하일때 노출
         */
        const v = Number(version);
        if (
          packageName === 'com.topco.toptoonglobal.global_toptoonplus_app' &&
          (_.isNaN(v) || v < 97)
        ) {
          setAppBlock(true);
          openAppUpdateModadl();
        }

        /**
         * login 상태인제 fcmToken이 바뀐경우
         */
        if (fcmToken !== '' && oldFcmToken !== `${fcmToken}` && token !== '') {
          const fcmToken = AppManager.getInstance().getFcmToken();
          const appVersion = AppManager.getInstance().getAppVersion();
          updateFcmToken(token, fcmToken, `${appVersion}`);
        }
      }
    }
  }, [router, isLoading]);

  /**
   * ANCHOR: 이벤트 기간중 사용할 className
   * 할로윈, 크리스마스 등등
   */
  const eventCss = useMemo((): string => {
    if (!isEvent() || !isMount) return '';

    if (router.pathname === '/') {
      return 'event_home';
    } else {
      return 'event_etc';
    }
  }, [router.pathname, isMount]);

  const DailyEventDomCss = useMemo((): string => {
    if (!isEvent() || !isMount) return '';

    const { mature } = UserSession.getUserInfo();

    // 비성인 메인 페이지 (연재페이지랑 돔 같은 모양)
    if (mature === 0 && router.pathname === '/') return 'event_main_daily';

    // 연재
    if (router.pathname === '/ongoing') return 'event_daily';

    return '';
  }, [router.pathname, isMount]);

  if (appBlock) {
    return null;
  }

  return (
    <div className={eventCss}>
      <Header hidden={headerHidden} />
      <div
        id="bodyWrapperCon"
        className={cn(
          `max-w-[1200px] w-full py-[30px] mx-auto px-[10px] h-auto min-h-[70vh] mo:min-h-[65vh]`,
          'tablet:my-0 tablet:py-0',
          // 회차리스트 페이지 헤더 비노출 조건에 맞춰 CSS 수정 함
          {
            ['tablet:px-0']: headerHidden,
            ['middle:px-[15px] mo:px-[10px]']:
              bodyWrapperHidden && headerHidden,
            ['max-w-full !mt-0  !py-0 px-0 middle:px-0 mo:!px-0 min-h-0 mo:min-h-0']:
              bodyWrapperHidden,

            ['!py-0 tablet:!mt-0']:
              (DailyEventDomCss === 'event_daily' ||
                DailyEventDomCss === 'event_main_daily') &&
              isMount,
          },
        )}
      >
        {DailyEventDomCss && isMount ? (
          <div
            className={cn(`${DailyEventDomCss} max-w-[1200px] w-full mx-auto`)}
          >
            {children}
          </div>
        ) : (
          <>{children}</>
        )}
      </div>
      <Footer hidden={footerHidden} />
      <ApiResponse />
      <GlobalEventResponse />
      {/* 모든페이지에 노출되는 모달 순서 controller */}
      <GlobalModalController />
      <PersistResponse />
      <UserCoinResponse />
      <AcceptCookieComponent />

      {isTestMod && <TestToolBox />}
    </div>
  );
};
export default TheLayout;
